import React from "react";
import { Link } from "react-router-dom";

import classes from "./Home.module.css";
import Hero from "../../Assets/coming-soon-hero.png";

const HomeContents = () => {
  return (
    <section className={classes.home}>
      <img className={classes.hero} src={Hero} alt="black-dog-custom-goods" />
    </section>
  );
};

export default HomeContents;
