import { Routes, Route, Redirect } from "react-router-dom";
import React from "react";
import './App.css';
import Navigation from "./Components/Navigation/Navigation";
import Home from "./Pages/Home"


function App() {
  return (
    <Navigation>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Navigation>
  );
}

export default App;
