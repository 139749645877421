import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import Logo from "../../Assets/logo-white-text.svg";
import Hamburger from "../../Assets/hamburger-icon-white.svg";


const MainNavigation = () => {
  const navigate = useNavigate();
  const [mobileMenu, setMobileMenu] = useState(false);

  // Have 2 menus, one for desktop and tablets, one for mobile. Mobile will need click function to hide/show menu
  const toggleMobileMenu = (event) => {
    setMobileMenu(!mobileMenu)
  }

  return (
    <React.Fragment>
      <header className={classes.headerContainer}>
        <div className={classes.headerTopRow}>
          <Link to="/">
            <img className={classes.logo} src={Logo} alt="black-dog-custom-goods" />
          </Link>
          <nav>
            <ul className={classes.navLinks}>
              <li>
                  <Link to="/">About Us</Link>
              </li>
              <li>
                  <Link to="/">Catalog</Link>
              </li>
              <li>
                  <Link to="/">Gallery</Link>
              </li>
              <li>
                  <Link to="/">Custom Work</Link>
              </li>
              <li className={classes.navButton}>
                  <Link to="/">Etsy Shop</Link>
              </li>
            </ul>
          </nav>
          <img className={classes.hamburgerIcon} onClick={toggleMobileMenu} src={Hamburger}/>
        </div>
        <div>
          <nav>
            <ul className={mobileMenu ? classes.mobileNavLinks : classes.mobileNavLinksHidden}>
              <li>
                  <Link to="/">About Us</Link>
              </li>
              <li>
                  <Link to="/">Catalog</Link>
              </li>
              <li>
                  <Link to="/">Gallery</Link>
              </li>
              <li>
                  <Link to="/">Custom Work</Link>
              </li>
              <li className={classes.navButton}>
                  <Link to="/">Etsy Shop</Link>
              </li>
            </ul>
          </nav>
        </div>
        
      </header>
    </React.Fragment>
  );
};

export default MainNavigation;
